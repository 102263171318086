import axios from "axios";
import lib from "./lib";

const privates = {
  lang: "",
  $target: null,
  observer: null,
  timer: 0,
  dictionary: {},
  translatingTexts: [],
  hasKorean(str) {
    return /[가-힣]/.test(str);
  },
  isTranslatableStr(str) {
    return str && this.hasKorean(str); // 문자열이 있고, 문자열에 한글이 포함되어 있는지
  },
  extractTexts(element, texts = []) {
    for (let node of element.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent?.trim();
        this.isTranslatableStr(text) && texts.push(text);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        this.extractTexts(node, texts);
      }
    }

    return texts;
  },
  applyTexts(element, texts) {
    for (let node of element.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const korean = node.textContent?.trim();

        if (this.isTranslatableStr(korean)) {
          const english = texts.shift();
          this.dictionary[korean] = english;
          node.textContent = english;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        this.applyTexts(node, texts);
      }
    }
  },
};

const exports = {
  translate() {
    const texts = privates.extractTexts(privates.$target);

    // 번역 중(HTTP 통신 중)인 텍스트와 지금 요청하려는 텍스트가 일치하면 중단
    if (window.JSON.stringify(privates.translatingTexts) === window.JSON.stringify(texts)) {
      return;
    }

    // 사전에 등록된 단어가 있으면 치환
    texts.forEach((t, i) => privates.dictionary[t] && (texts[i] = privates.dictionary[t]));

    // 한글이 하나도 없다면 자체 번역
    if (!texts.some(t => privates.hasKorean(t))) {
      privates.applyTexts(privates.$target, texts);
      return;
    }

    privates.translatingTexts = texts;
    axios.post(`/v2/api/translate?lang=${privates.lang}`, {texts}).then((res) => {
      privates.translatingTexts = [];
      Array.isArray(res.data) && res.data.length && privates.applyTexts(privates.$target, res.data);
    });
  },
  init($target) {
    privates.$target = $target;

    if (!privates.$target) {
      console.error("target element does not exist.");
      return;
    }

    const queryParams = lib.getQueryParameters(window.location.search);
    const queryLang = queryParams["lang"];

    if (queryLang) {
      if (queryLang === "en") {
        window.sessionStorage.setItem("lang", "en");
      } else {
        window.sessionStorage.removeItem("lang");
        return;
      }
    }

    privates.lang = window.sessionStorage.getItem("lang");

    if (!privates.lang) {
      return;
    }

    privates.observer = new MutationObserver(() => {
      clearTimeout(privates.timer);
      privates.timer = setTimeout(this.translate, 1000);
    });

    privates.observer.observe(privates.$target, {
      childList: true,   // 자식 노드의 추가/제거를 관찰합니다.
      subtree: true,     // 하위 트리의 변경 사항도 관찰합니다.
      characterData: true // 텍스트 노드의 데이터 변경을 관찰합니다.
    });
  },
};

export default exports;